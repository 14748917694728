<template>
	<div class="page_game">
		<top :bgWhite='true'></top>
		<div class="contentAll  padding-lr-30  top-80   ">
			<div class="flex align-center justify-between">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/my' }">个人中心</el-breadcrumb-item>
					<el-breadcrumb-item>赛事中心</el-breadcrumb-item>
				</el-breadcrumb>
				<el-button type="danger" round size="mini">
					<router-link to="/my_game">
						<p class="text-white">我的参赛</p>
					</router-link>
				</el-button>
			</div>
			<div class="margin-top-20 lists">
				<div class="" v-if="isInit">
					<el-row :gutter="24">
						<el-col :span="6" v-for="(item,index) in lists" :key="index">
							<div class="list-item bg-white point" @click="toDetail(item)">
								<el-image :src="item.thumb" class="cover-img" fit="cover"></el-image>
								<div class="padding-10">
									<div class="">
										<p class="fs11">{{item.event_name}}</p>
										<p class="text-666 fs9 margin-top-5">赛事类型：{{item.event_type}}</p>
									</div>
									<div class="flex align-center margin-top-10">
										<img src="../../assets/image/icon/push.png" class="block-20 margin-right-5">
										<p class="fs9 text-666">发布时间：{{item.create_time}}</p>
									</div>
									<div class="flex align-center margin-top-10">
										<img src="../../assets/image/icon/time.png" class="block-20 margin-right-5">
										<p class="fs9 text-666">结束时间：{{item.end_time}}</p>
									</div>
								</div>
							</div>
						</el-col>
						<div class="" v-if="lists.length==0">
							<el-empty description="~空空如也~"></el-empty>
						</div>
					</el-row>
				</div>
				<!-- 加载中 -->
				<div class="w100" v-else>
					<el-skeleton :rows="12" animated />
				</div>
			</div>
			<!-- 分页 -->
			<div class="flex align-center justify-center">
				<el-pagination background  layout="prev, pager, next" :total="totalSize" :page-size='10' :hide-on-single-page='true' @current-change="currentChange">
				</el-pagination>
			</div>
			<!-- 分页 end-->
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		components: {
			bottom,
			top
		},
		data(){
			return{
				isInit:false,
				totalSize:0,
				page:1,
				lists:[]
			}
		},
		mounted(){
			this.getGameLists()
		},
		methods:{
			// 点击当前页
			currentChange(e){
				this.page = e;
				this.getLogLists()
			},
			toDetail(item){
				this.$router.push({
					path:'/center_detail',
					query:{
						event_aid:item.aid
					}
				})
			},
			// 获取赛事列表
			getGameLists(){
				this.ajax('post', '/v1/613b19dd71c62', {}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.lists =  res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			}
		}
	}
</script>

<style  lang="scss">
html, body{
	background: #F6F7F9 !important;
	.content{
		width: 100%;
		background: #FFFFFF;
	}
}
.contentAll{
	width: 60%;
}
.list-item{
	box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.06);
	.cover-img{
		width: 100%;
		height: 88px;
		background: rgba(0, 0, 0, 0);
		opacity: 1;
		border-radius: 5px 5px 0px 0px;
	}
}
.active {
	position: relative;
	color: #333;
	font-weight: bold;
	font-size: 16px;
}

.active::after {
	position: absolute;
	content: '';
	bottom: 0;
	left: 0;
	right: 0;
	width: 30%;
	margin: auto;
	background-color: #E54F42;
	height: 2px;
}
.item {
	width: 300px;
	height: 32px;
	line-height: 48px;
	background-color: #F6F7F9 !important;
	border: 0;
	border-radius: 24px;
	.input {
		width: 85%;
		font-size: 9px;
	}
	.send-code{
		font-size: 9px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 13px;
		color: #3E62B1;
		opacity: 1;
	}
}

</style>